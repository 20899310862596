import * as bootstrap from 'bootstrap';
import Swiper from 'swiper/bundle';
import Helper from './helper';
import AOS from 'aos';

import 'swiper/css/bundle';

import.meta.glob([
    '../img/**'
]);

class App {
    constructor() {
        this.init();
    }

    init() {
        this.AOS();

        this.carousels();

        this.accordions();

        this.vacancies();

        this.timeline();

        this.cookies();

        this.asyncForms();

        this.initProductsheetForm();
    }

    AOS() {
        AOS.init({
            once: true
        });
    }

    carousels() {
        document.querySelectorAll('.hero-carousel').forEach(element => {
            new Swiper('#' + element.getAttribute('id'), {
                pagination: {
                    el: element.parentElement.querySelector('.swiper-pagination'),
                    clickable: true,
                    type: 'bullets'
                },
                slidesPerGroup: 1,
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                autoplay: {
                    delay: 5000
                }
            });
        });

        document.querySelectorAll('.reference-carousel').forEach(element => {
            new Swiper('#' + element.getAttribute('id'), {
                pagination: {
                    el: element.parentElement.querySelector('.swiper-pagination'),
                    clickable: true,
                    type: 'bullets'
                },
                breakpoints: {
                    0: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 3
                    },
                    992: {
                        slidesPerView: 4
                    },
                    1200: {
                        slidesPerView: 6
                    },
                    1400: {
                        slidesPerView: 8
                    },
                    1600: {
                        slidesPerView: 9
                    }
                },
                slidesPerGroup: 1,
                spaceBetween: 40,
                loop: true,
                autoplay: {
                    delay: 5000
                }
            });
        });

        document.querySelectorAll('.photo-carousel').forEach(element => {
            new Swiper('#' + element.getAttribute('id'), {
                breakpoints: {
                    0: {
                        slidesPerView: 1
                    },
                    576: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 3
                    },
                    992: {
                        slidesPerView: 4
                    },
                    1200: {
                        slidesPerView: 'auto'
                    }
                },
                slidesPerGroup: 1,
                spaceBetween: 40,
                loop: false,
                autoplay: {
                    delay: 5000
                }
            });
        });
    }

    accordions() {
        document.querySelectorAll('.accordion-item').forEach(accordionItem => {
            // Bind show event listener to the accordion item
            accordionItem.addEventListener('show.bs.collapse', function() {
                // Add active state
                this.classList.add('active');
            });

            // Bind hide event listener to the accordion item
            accordionItem.addEventListener('hide.bs.collapse', function() {
                // Remove active state
                this.classList.remove('active');
            });
        });
    }

    vacancies() {
        // Get the last url segment
        let lastSegment = window.location.pathname.split('/').pop();

        document.querySelectorAll('.accordion-item').forEach(accordionItem => {
            // Check if the last url segment matches the data key
            if (accordionItem.dataset.key === lastSegment) {
                // Scroll to accordion item
                window.scrollTo({
                    top: accordionItem.offsetTop
                });
            }
        });
    }

    timeline() {
        document.querySelectorAll('.timeline').forEach(timeline => {
            // Get all timeline items
            let timelineItems = timeline.querySelectorAll('.timeline-item');

            // Loop through all timeline items
            timelineItems.forEach(timelineItem => {
                let buttonToggle = timelineItem.querySelector('.btn-toggle');

                if (buttonToggle) {
                    // Bind click event listener to the button toggle
                    buttonToggle.addEventListener('click', function() {
                        // Toggle active state
                        timelineItem.classList.toggle('active');
                    });
                }
            });
        });
    }

    /**
     * Set cookie preferences
     */
    cookies() {
        let form = document.getElementById('form-cookies');

        if (form === null) {
            return false;
        }

        // Show the cookie form
        form.classList.remove('fade');

        // Add cookie selection state
        document.body.classList.add('cookie-selection');

        let options = form.querySelector('.cookie-options');

        let textChecked   = 'Alle cookies accepteren';
        let textUnchecked = 'Geselecteerde cookies accepteren'

        let buttonSubmit = form.querySelector('button[type="submit"]');

        let isAllCookiesChecked = function () {
            let checked = true;

            options.querySelectorAll('.form-check-input').forEach(switchElement => {
                if (switchElement.checked === false) {
                    checked = false;
                }
            });

            return checked;
        }

        options.querySelectorAll('.form-check-input').forEach(input => {
            // Bind the change event listener to the form check input
            input.addEventListener('change', function () {
                buttonSubmit.innerText = isAllCookiesChecked() ? textChecked : textUnchecked;
            });

            // Manually create on on-change event
            let event = new Event('change');

            // Manually dispatch event
            input.dispatchEvent(event);
        });

        form.addEventListener('submit', function(e) {
            e.preventDefault();

            Helper.post(this.action, new FormData(this)).then(() => {
                // Remove cookie selection state
                document.body.classList.remove('cookie-selection');

                // Fade out the form
                form.classList.add('fade');

                setTimeout(function () {
                    // Reload page
                    location.reload();
                }, 1000);
            })
            .catch(error => {
                // Reject the promise
                return Promise.reject(error.statusText);
            });
        });
    }

    asyncForms() {
        let gRecaptchaWidgets = [];

        document.querySelectorAll('.g-recaptcha-widget').forEach(recaptcha => {
            let widgetId = grecaptcha.render(recaptcha.id, {
                'sitekey' : config.recaptcha_site_key
            });

            gRecaptchaWidgets.push(widgetId);
        });

        /**
         * Refresh reCAPTCHA
         */
        let refreshRecaptcha = () => {
            // Check if reCAPTCHA is loaded
            if (grecaptcha == undefined) {
                return;
            }

            // Refresh all recaptcha widget instances
            gRecaptchaWidgets.forEach(widgetId => {
                grecaptcha.reset(widgetId);
            });
        }

        setInterval(function() {
            refreshRecaptcha();
        }, 2 * 60 * 1000);

        document.querySelectorAll('.form-async').forEach(form => {
            form.addEventListener('submit', (e) => {
                e.preventDefault();

                // Serialize all form data
                let formData = new FormData(form);

                // Fetch container for the form messages
                let formMessages = form.querySelector('.form-messages');

                // Clear the form errors
                form.querySelectorAll('[data-error]').forEach(errorMessage => {
                    // Clear error message
                    errorMessage.innerHTML = '';

                    // Remove multiple status class
                    errorMessage.classList.remove('multiple');
                });

                // Clear the form messages
                formMessages.replaceChildren();

                Helper.post(form.getAttribute('action'), formData).then(response => {
                    if (response.redirect_url) {
                        // Redirect to the given URL
                        window.location.href = response.redirect_url;
                    }
                    else {
                        // Reset form
                        form.reset();

                        // Refresh reCAPTCHA
                        refreshRecaptcha();

                        formMessages.innerHTML = '<div class="alert alert-basic alert-' + response.message.type + '"><span>' + response.message.text + '</span></div>';
                    }
                })
                .catch(error => {
                    let statusCode = error.statusCode;

                    switch (statusCode) {
                        case 422:
                            // Set error messages
                            Object.entries(error.response.errors).forEach(([key, errors]) => {
                                // Find the target element with data-error attribute
                                const targetElement = form.querySelector('[data-error="' + key + '"]');

                                if (targetElement === null) {
                                    return;
                                }

                                if (errors.length > 1) {
                                    // Mark container as multiple errors
                                    targetElement.classList.add('multiple');
                                }

                                errors.forEach(function (error) {
                                    // Create a new <span> element
                                    const errorSpan = document.createElement('span');

                                    // Set the text content of the <span>
                                    errorSpan.textContent = error;

                                    // Append the <span> as a child of the target element
                                    targetElement.appendChild(errorSpan);
                                });
                            });
                            break;

                        default:
                            // Show notification
                            alert('Er is iets misgegaan.');
                    }

                    // Refresh reCAPTCHA
                    refreshRecaptcha();

                    // Reject the promise
                    return Promise.reject(error.statusText);
                });
            });
        });
    }

    initProductsheetForm() {
        let modalProductsheet = document.getElementById('productsheet-modal');

        if (modalProductsheet === null) {
            return;
        }

        Helper.bindEventListener(document, 'click', '[data-action="download-productsheet"]', function () {
            modalProductsheet.querySelector('input[name="token"]').value = this.dataset.token;

            // Show the modal
            bootstrap.Modal.getOrCreateInstance(modalProductsheet).show();
        });
    }
}

window.addEventListener('load', () => new App());
